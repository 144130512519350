import {request} from '@/common/request/sentry.js'
export const auth = {
	login(data){
		return request.post('/sentry/auth/login',data)
	},
	logout(){
		return request.post('/sentry/auth/logout')
	},
}

export const account = {
	info(params){
		return request.get('/sentry/account/info', {params})
	}
}

export const project = {
	get(params){
		return request.get('/sentry/project/get',{params})
	},
	all(params){
		return request.get('/sentry/project/all',{params})
	},
	create(data){
		return request.post('/sentry/project/create',data)
	},
	delete(data){
		return request.post('/sentry/project/delete',data)
	},
	addNoticeEmail(data){
		return request.post('/sentry/project/addNoticeEmail',data)
	},
	removeNoticeEmail(data){
		return request.post('/sentry/project/removeNoticeEmail',data)
	}
}

export const event = {
	get(params){
		return request.get('/sentry/event/get',{params})
	},
	all(params){
		return request.get('/sentry/event/all',{params})
	},
	stat(params){
		return request.get('/sentry/event/stat',{params})
	},
	group(params){
		return request.get('/sentry/event/group',{params})
	},
	group_stat(params){
		return request.get('/sentry/event/group_stat',{params})
	},
}

export const code = {
	email(data){
		return request.post('/sentry/code/email',data)
	}
}

export const node = {
	get(params){
		return request.get('/sentry/node/get',{params})
	},
	all(params){
		return request.get('/sentry/node/all',{params})
	},
	create(data){
		return request.post('/sentry/node/create',data)
	},
	delete(data){
		return request.post('/sentry/node/delete',data)
	},
	start(data){
		return request.post('/sentry/node/start',data)
	},
	stop(data){
		return request.post('/sentry/node/stop',data)
	}
}

